<template>
  <div>
    <Modal modal-class="slide-up" ref="modal_create_entry" size="lg" :on-hide="clearEntryToSave"
           :modal-header-title="$t('users.create hotel user')">
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
            <div class="modal-body">
              <AppInput name="name" :label="$t('common.name')" type="string" rules="required"
                        v-model="entryToSave.name"/>
              <AppInput name="email" :label="$t('common.mail address')" type="email"
                        :rules="V_EMAIL_REQUIRED"
                        v-model="entryToSave.email"/>
              <AppInput name="phone" :label="$t('common.phone')" type="number-length:11" rules="required|phone"
                        v-model="entryToSave.phone"/>
              <AppSelect :options="{placeholder: $t('hotel.hotel_name')}" name="hotel_id"
                         :label="$t('hotel.hotel_name')" v-model="entryToSave.hotel_id"
                         :options-data="listHotel"
                         rules="required">
              </AppSelect>
              <AppRadio
                :radio-inputs="[
                  {label: $t('common.active'), value: 1},
                 {label:$t('common.deactive'), value: 0}
                 ]" name="TO_plan" :label="$t('common.status')" rules="required" v-model="entryToSave.active">
              </AppRadio>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_edit_entry" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="$t('users.update user')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)">
            <div class="modal-body">
              <AppInput name="name" :label="$t('common.name')" type="string" rules="required"
                        v-model="entryToEdit.name"/>
              <AppInput name="email" :label="$t('common.mail address')" type="email"
                        :rules="V_EMAIL_REQUIRED"
                        v-model="entryToEdit.email"/>
              <AppInput name="phone" :label="$t('common.phone')" type="number-length:11" rules="required|phone"
                        v-model="entryToEdit.phone"/>
              <AppSelect :options="{placeholder: $t('hotel.hotel_name')}" name="hotel_id"
                         :label="$t('hotel.hotel_name')" v-model="entryToEdit.hotel_id"
                         :options-data="listHotel"
                         rules="required">
              </AppSelect>
              <AppRadio
                :radio-inputs="[
                  {label: $t('common.active'), value: 1},
                 {label:$t('common.deactive'), value: 0}
                 ]" name="TO_plan" :label="$t('common.status')" rules="required" v-model="entryToEdit.active">
              </AppRadio>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      tableName: this.$t('hotel.hotel user list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.USERS_LIST,
          params: {"filters[role][equal]": this.$consts.ROLES.ROLE_HOTEL_NAME}
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.USERS_DELETE,
        }
      },
      actions: {
        editEntry: this.editEntry,
        createEntry: this.createEntry,
        others: [
          {
            showCondition: entry => !entry.email_verified_at && entry.active,
            label: '認証URLの再送信',
            click: entry => this.resentActiveEmail(entry),
            contentHtml: entry => `<div class="btn-tool default text-center">認証URLの再送信</div>`,
          },
        ]
      },
      tools: {
        search: {
          // reset: true,
          filters: [
            {
              name: 'hotel_id',
              type: 'equal',
              label: this.$t('hotel.hotel_name'),
              // uiType: 'select',
            }
          ]
        }
      },
      listHotel: [],
      entrySelectedDelete: {},
      entryToSave: {active: 1},
      entryToEdit: {},
      columns: [
        //{name: 'id', label: 'ID', fitSize: true},
        {name: 'hotel.name', label: this.$t('hotel.hotel_name'), sortable: false},
        {name: 'name', label: this.$t('common.name'), detailHeader: true},
        {name: 'email', label: this.$t('common.mail address'), sortable: false},
        {name: 'phone', label: this.$t('common.phone'), sortable: false},
        {
          name: 'active',
          label: this.$t('common.status'),
          class: "text-center",
          computedHtml: entry => {
            if (entry.active) {
              return '<span class="alert alert-info">' + this.$t('common.active') + '</span>'
            } else {
              return '<span class="alert alert-disable">' + this.$t('common.deactive') + '</span>'
            }
          },
          sortable: false
        },
        {name: 'creator', label: this.$t('common.creator'), listShow: false},
        {name: 'created_at', label: this.$t('common.created at'), listShow: false},
        {name: 'updater', label: this.$t('common.updater'), listShow: false},
        {name: 'updated_at', label: this.$t('common.updated at'), listShow: false},
      ]
    }
  },
  components: {BasicTable},
  mounted() {
    this.$request.get(this.$consts.API.ADMIN.HOTEL_LIST_SELECT).then(res => {
      if (!res.hasErrors()) {
        this.listHotel = res.data.data;
        // this.setOptionFilter(this.tools, 'hotel_id', 'equal', this.listHotel);
      }
    })
  },
  methods: {
    resentActiveEmail(entry) {
      this.$request.post(this.$consts.API.ADMIN.RESENT_ACTIVE_EMAIL(entry.id)).then(res => {
        if (!res.hasErrors()) {
          this.$appNotice.success('メールを送信しました。');
        } else {
          this.$appNotice.error();
        }
      })
    },
    /* CREATE entry start */
    clearEntryToSave() {
      this.entryToSave = {active: 1}
      this.$refs.form_create_entry.reset()
    },
    createEntry() {
      this.$refs.modal_create_entry.show();
    },
    async saveEntry() {
      const {name, email, phone, hotel_id, active} = this.entryToSave
      const res = await this.$request.post(this.$consts.API.ADMIN.USERS_SAVE, {
        name, email, phone, hotel_id, active, role: this.$consts.ROLES.ROLE_HOTEL_NAME
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$refs.modal_create_entry.hide();
        this.entryToSave = {active: 1}
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_create_entry.setErrors(res.data.errors);
        } else if (res.data.error_msg) {
          this.$refs.form_create_entry.setErrors({
            email: [res.data.error_msg]
          });
        } else {
          this.$appNotice.error()
        }
      }
    },
    /* CREATE entry end */

    /* UPDATE entry start */
    editEntry(entry) {
      this.entryToEdit = _.clone(entry)
      this.$refs.modal_edit_entry.show();
    },

    async updateEntry() {
      const {name, email, phone, hotel_id, active} = this.entryToEdit
      const res = await this.$request.patch(this.$consts.API.ADMIN.USERS_EDIT(this.entryToEdit.id), {
        name, email, phone, hotel_id, active
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$refs.modal_edit_entry.hide();
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_entry.setErrors(res.data.errors);
        } else if (res.data.error_msg) {
          this.$refs.form_create_entry.setErrors({
            email: [res.data.error_msg]
          });
        } else {
          this.$appNotice.error()
        }
      }
    },
    clearEntryToEdit() {
      this.$refs.form_update_entry.reset()
    },
    /* UPDATE entry end */
  }
}
</script>
